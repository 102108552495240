<script setup lang="ts">
import {
  ArrowDownTrayIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/vue/24/solid';
import type { Media } from '@libero/api-client/media/types';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import DropdownBody from '@libero/ui-framework/Dropdown/DropdownBody.vue';
import DropdownFooter from '@libero/ui-framework/Dropdown/DropdownFooter.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import PreviewModal from '@libero/ui-framework/PreviewModal/PreviewModal.vue';
import { getMediaExtension, getMediaIcon, getMediaSize } from '@libero/utilities/media';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  media: Media;
  onRemove?: (id: string) => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const handleRemove = () => {
  props.onRemove?.(props.media.uuid);
};

const icon = computed(() => getMediaIcon(props.media));
</script>

<template>
  <div v-if="media" class="media-item">
    <div class="media-item-content">
      <Icon size="xl">
        <component :is="icon" />
      </Icon>

      <div class="media-item-info">
        <span class="media-item-type">{{ getMediaExtension(media) }}</span>
        <span class="media-item-size">{{ getMediaSize(media) }}</span>
      </div>

      <span class="media-item-name">
        {{ media.file_name || media.name }}
      </span>
    </div>

    <Dropdown>
      <template #trigger>
        <IconButton size="sm" appearance="link" color="secondary">
          <EllipsisVerticalIcon />
        </IconButton>
      </template>

      <template #content>
        <DropdownBody>
          <PreviewModal v-slot="{ openModal }" :media="media">
            <DropdownAction :onClick="openModal">
              <EyeIcon class="dropdown-action-icon" />
              {{ t('preview') }}
            </DropdownAction>
          </PreviewModal>

          <DropdownAction :href="media.temporary_url" download>
            <ArrowDownTrayIcon class="dropdown-action-icon" />
            {{ t('download') }}
          </DropdownAction>
        </DropdownBody>

        <DropdownFooter v-if="onRemove">
          <DropdownAction color="error" :onClick="handleRemove">
            <TrashIcon class="dropdown-action-icon" />
            {{ t('destroy') }}
          </DropdownAction>
        </DropdownFooter>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/MediaItem/MediaItem.scss';
</style>
