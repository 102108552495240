import { DocumentTextIcon } from '@heroicons/vue/24/solid';
import { Media } from '@libero/api-client/media/types';
import excel from '@libero/theme/icons/documents/excel.svg';
import image from '@libero/theme/icons/documents/image.svg';
import pdf from '@libero/theme/icons/documents/pdf.svg';
import powerpoint from '@libero/theme/icons/documents/powerpoint.svg';
import word from '@libero/theme/icons/documents/word.svg';
import { FunctionalComponent } from 'vue';

const mimeTypeMap = {
  'image/png': image,
  'image/jpeg': image,
  'image/jpg': image,
  'image/gif': image,
  'application/pdf': pdf,
  'application/msword': word,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': word,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': word,
  'application/vnd.ms-word.document.macroEnabled.12': word,
  'application/vnd.ms-word.template.macroEnabled.12': word,
  'application/vnd.ms-excel': excel,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excel,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': excel,
  'application/vnd.ms-excel.sheet.macroEnabled.12': excel,
  'application/vnd.ms-excel.template.macroEnabled.12': excel,
  'application/vnd.ms-excel.addin.macroEnabled.12': excel,
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12': excel,
  'application/vnd.ms-powerpoint': powerpoint,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': powerpoint,
  'application/vnd.openxmlformats-officedocument.presentationml.template': powerpoint,
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': powerpoint,
  'application/vnd.ms-powerpoint.addin.macroEnabled.12': powerpoint,
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12': powerpoint,
  'application/vnd.ms-powerpoint.template.macroEnabled.12': powerpoint,
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': powerpoint,
};

export const mimeTypes = Object.keys(mimeTypeMap);

export const getCurrentMedia = (media?: Media[]): Media | undefined => {
  return media?.at(-1);
};

export const getMediaIcon = (media?: Pick<Media, 'mime_type'>): FunctionalComponent => {
  if (!media) return DocumentTextIcon;

  const fileTypeIcon = mimeTypeMap[media.mime_type as keyof typeof mimeTypeMap];
  if (fileTypeIcon) return fileTypeIcon as unknown as FunctionalComponent;

  return DocumentTextIcon;
};

export const getMediaExtension = (media?: Media): string | undefined => {
  return media?.file_name?.split('.')?.at(-1);
};

export const getMediaIsImage = (media?: Media): boolean => {
  return media?.mime_type?.startsWith('image') || false;
};

export const getMediaSize = (media?: Media): string | undefined => {
  if (!media?.size) return;

  const kilobytes = media.size / 1024;
  const megabytes = kilobytes / 1024;

  if (megabytes < 0.5) return `${kilobytes.toFixed(0)} KB`;

  return `${megabytes.toFixed(2)} MB`;
};
